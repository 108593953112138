import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/service/JwtService";
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description initialize vue axios
   */
  static init(app) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API;
  }
  /**
   * @description set the default HTTP request headers
   */
  static setHeader() {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static query(resource, params) {
    return ApiService.vueInstance.axios
      .get(resource, {
        params: {
          title: params,
        },
      })
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  static get(resource) {
    return ApiService.vueInstance.axios.get(`${resource}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }
  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static post(resource, params) {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }
  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static update(resource, slug, params) {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }
  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static put(resource, params) {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }
  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  static delete(resource) {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}
export default ApiService;
