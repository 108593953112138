import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ApiService from "@/service/ApiService";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(App);
ApiService.init(app);
app.component("Loading", Loading);
app.use(store);
app.use(router);
app.provide("store", store);
app.provide("router", router);
app.mount("#app");
