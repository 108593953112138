<template>
  <div class="bg-home vh100">



    <div class="row align-tem-center justify-content-center pd-16">



      <img src="@/assets/logo.png" style="height: 10rem; width: auto; margin-top: 2rem;" alt="..." />


      <div class="col-12 mt-3 title2 text-white text-center">ยินดีต้อนรับสู่งานวันเด็ก</div>
      <div class="col-12 title2 text-white text-center">ประจำปี 2566</div>
      <div class="col-12 title1 text-white text-center">เทศบาลเมืองหัวหิน</div>

      <div class="card-body">
        <div class="box-main">


          <div class="profile-picture">
            <img :src="ProfireLine.pictureUrl">
          </div>

          <div class="title1 mt-2 text-black text-center">คุณ {{ Profire.firstname }} {{ Profire.lastname }} </div>
          <div class="container" v-if="Profire.babys[0]">
            <div class="title1 mt-2 text-black text-center">รายชื่อบุตรหลาน</div>
            <ul class="b mt-2" v-for="(item, index) in Profire.babys" :key="index">
              <li> {{ index + 1 }}. {{ (item.fn == 'อื่นๆ' ? item.fn_other : item.fn) }} {{ item.firstname }} {{
                item.lastname
              }} อายุ {{ item.age }} ปี  </li> 
              <!-- <i @click="del_baby(item.id,index)" class="bi bi-x-circle icon"></i> -->
            </ul>
          </div>


        </div>
      </div>


      <!-- <div class="row mb-2 justify-content-end">
        <button type="button" class="submit-button me-4 mt-3">
          กลับไปหน้าไลน์
        </button>
      </div> -->
      <button type="button" @click="go_add_baby" class="submit-button">
        เพิ่มรายชื่อบุตรหลาน
      </button>




      <div class="row align-tem-center justify-content-center pd-16">
        <button type="button" @click="close" class="btn btn-link text-white">กลับไปหน้าไลน์</button>
      </div>




    </div>

  </div>


</template>

<script>
// import useSweetalert from "@/service/sweetalert2";
// import useRegister from "@/service/api/register";
import liff from "@line/liff";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject } from "vue";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });


    const go_add_baby = () => {
      router.push({ name: "Registerbaby" });
    };

    const del_baby = (id,index) => {
      console.log(id);
      Profire.babys.slice(index, 1);
    };

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };





    return {
      go_add_baby,
      fn_status,
      iconMale,
      iconFemale,
      ProfireLine,
      Profire,
      del_baby,
      close
    };
  },
});

</script>

<style scoped>

.icon{
  color: red !important;
}
.profile1 {
  font-size: 16px;
  font-weight: medium;
}

.profile2 {
  font-size: 25px;
  font-weight: medium;
}

.box-main {
  color: #373737 !important;
  position: relative;
  box-sizing: border-box;
  height: auto;
  margin-top: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 20px;
  background: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 16px;

  align-items: center;
  display: flex;
  flex-direction: column;


}

.header {
  background-image: url("~@/assets/header.png");
  object-fit: cover;
  height: 150px;
  border: none;
  padding: 0;
}

.title1 {
  font-size: 20px;
  font-weight: medium;
}

.title2 {
  font-size: 25px;
  font-weight: medium;
}

.card {
  color: #4E6629;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  border-radius: 25px;
  padding: 5px;
  background-image: url("~@/assets/card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card-header {
  margin-top: 30px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-picture {
  overflow: hidden;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.logo-picture {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.logo-picture img {
  width: 100%;
}

.submit-button {
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 150px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.bg-home {
  background-color: var(--color);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px;
}

.bg-home img {
  position: relative;
  object-fit: cover;
  width: 100%;
  border: none;
  height: auto;
  padding-top: 0;

}
</style>
