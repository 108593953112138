<template>
  <div class="container-fluid" style="padding: 0; overflow-x: hidden">
    <router-view></router-view>
  </div>
</template>

<style>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";
:root {
  --color: #068693;
}
html,
body {
  font-family: "Sarabun", sans-serif !important;
  font-size: 14px;
}
a {
  color: black !important;
}
.text-root {
  color: var(--color);
}
.f13 {
  font-size: 13px !important;
}
.t1 {
  font-size: 15px;
}
.fw-5 {
  font-weight: 500;
}
a {
  text-decoration: none !important;
}
.logoname {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.swal2-icon.swal2-error.swal2-icon-show {
  margin: auto;
}
.swal2-icon.swal2-success.swal2-icon-show {
  margin: auto;
}
.swal2-icon.swal2-question.swal2-icon-show {
  margin: auto;
}
.table td {
  border-width: 0em 0;
}
.vh100 {
  height: 100vh;
}

.concard {
  min-height: calc(100vh);
  padding: 0;
  background-color: whitesmoke !important;
}
.card-shadow {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}

.card-wrap {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  font-family: Sarabun, sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 2px solid var(--color) !important;
}
.card-body {
  flex: unset !important;
  padding: 16px;
}
.card-footer {
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
}
.mb-47 {
  margin-bottom: 4.7rem !important;
}
.white {
  background-color: white !important;
}

ol.sub-items {
  counter-reset: item;
  padding-left: 0 !important;
}
ol.sub-items li {
  display: block;
  margin-bottom: 10px;
}
ol.sub-items.indent {
  text-indent: 36px;
}
ol.sub-items li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
.button {
  font-family: Sarabun, sans-serif;
  font-weight: 500;
  font-size: 18px !important;
  border: 1px solid var(--color);
  border-radius: 8px;
  color: #fff;
  background-color: var(--color);
  height: 44px !important;
  min-height: 44px !important;
  padding: 0 14px !important;
  letter-spacing: 0;
  text-indent: 0;
  text-transform: none;
  flex: 1;
}
.btn-back {
  width: 100%;
  background-color: white;
  color: var(--color);
}
.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #f1416c;
  padding-left: 0.25rem;
  font-weight: 700;
}
.form-control.form-control-solid {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.fv-plugins-message-container {
  margin-top: 0.3rem;
}
.fv-plugins-message-container .fv-help-block {
  color: #f1416c;
  font-size: 1rem;
  font-weight: 400;
}
</style>
