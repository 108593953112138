import Cookies from "js-cookie";

const ID_TOKEN_KEY = "token";

export const getToken = () => {
  return Cookies.get(ID_TOKEN_KEY);
};

export const saveToken = (token) => {
  Cookies.set(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  Cookies.remove(ID_TOKEN_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
};
