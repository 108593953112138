import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("register_baby_days", data);
  };

  const Register_babys = async (data) => {
    return await ApiService.post("register_babys", data);
  };

  const Del_babys = async (data) => {
    return await ApiService.post("delete_babys", data);
  };


  const UpdateUser = async (data) => {
    return await ApiService.put("edit_user/" + data.id_line, data);
  };

  return {
    Register,
    UpdateUser,
    Register_babys,
    Del_babys
  };
}
