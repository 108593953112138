import ApiService from "@/service/ApiService";

export default function useLogin() {
  const CheckUser = async (id) => {
    ApiService.setHeader();
    return await ApiService.query("checkregister_babyday/" + id);
  };

  return {
    CheckUser,
  };
}
